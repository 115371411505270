import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <Typography variant="h3" sx={{ mb: 2 }}>
                404 - Página Não Encontrada
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
                A página que você está procurando não existe.
            </Typography>
            <Button variant="contained" onClick={() => navigate('/login')}>
                Voltar para o Login
            </Button>
        </Box>
    );
};

export default NotFoundPage;

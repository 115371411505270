import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Import corrigido: agora é um named import
import { useNavigate } from 'react-router-dom';

// ✅ Criação do Contexto de Autenticação
export const AuthContext = createContext();

// ✅ Provedor de Autenticação
const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState({
        token: localStorage.getItem('token') || null,
        roleId: localStorage.getItem('role_id') || null,
        user: null,
    });

    // ✅ Verifica o token no carregamento do componente
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decoded = jwtDecode(token);

                // Verifica se o token está expirado
                if (decoded.exp * 1000 < Date.now()) {
                    console.warn('🔴 Token expirado. Fazendo logout...');
                    logout();
                    return;
                }

                // Atualiza o estado de autenticação
                setAuth({
                    token,
                    roleId: decoded.role_id,
                    user: decoded.sub,
                });
            } catch (err) {
                console.error('🔴 Erro ao decodificar token:', err);
                logout();
            }
        }
    }, []);

    // ✅ Realiza o login e configura o estado
    const login = (token) => {
        try {
            const decoded = jwtDecode(token);

            // Salva o token e role no localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('role_id', decoded.role_id);

            // Atualiza o estado de autenticação
            setAuth({
                token,
                roleId: decoded.role_id,
                user: decoded.sub,
            });

            console.info('🟢 Login bem-sucedido. Redirecionando...');
            navigate('/menu'); // Redireciona após login
        } catch (err) {
            console.error('🔴 Erro ao decodificar token durante o login:', err);
            logout();
        }
    };

    // ✅ Realiza o logout e reseta o estado
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role_id');
        setAuth({ token: null, roleId: null, user: null });
        console.info('🟡 Logout bem-sucedido. Redirecionando para página inicial.');
        navigate('/'); // Redireciona após logout
    };

    // ✅ Verifica se o usuário possui uma role específica
    const hasRole = (role) => auth.roleId === role;

    // ✅ Verifica se o usuário está autenticado
    const isAuthenticated = () => auth.token !== null;

    // ✅ Define as rotas disponíveis
    const routes = {
        public: [
            '/health/health',
            '/',
        ],
        authenticated: [
            '/chat/chat/',
            '/chat/chat/ping',
            '/profile/profile',
        ],
        admin: [
            '/bi/info', // ✅ Adicionada a rota para BI
            '/auth/roles',
            '/auth/users',
            '/admin/users',
            '/admin/roles',
            '/admin/assign-role',
            '/admin/users/{user_id}/password',
            '/admin/users/{user_id}',
        ],
        auth: [
            '/auth/login',
            '/auth/register',
        ],
    };

    return (
        <AuthContext.Provider value={{
            auth,
            login,
            logout,
            hasRole,
            isAuthenticated,
            routes,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

// ✅ Exportação Padrão
export default AuthProvider;

// src/theme/overrides/Chip.jsx
import { alpha } from '@mui/material/styles';

export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: {
        color: 'primary',
        variant: 'light'
      },

      // 1) Defina aqui as variants no mesmo nível de styleOverrides
      variants: [
        {
          props: { variant: 'light' },
          style: ({ ownerState }) => {
            const paletteColor = theme.palette[ownerState.color] || theme.palette.primary;
            return {
              ...(paletteColor && {
                color: paletteColor.main,
                backgroundColor: paletteColor.light,

                // Se for 'error', diminui a opacidade
                ...(ownerState.color === 'error' && {
                  backgroundColor: alpha(paletteColor.light, 0.25)
                }),

                // Se for 'success', outra opacidade
                ...(ownerState.color === 'success' && {
                  backgroundColor: alpha(paletteColor.light, 0.5)
                }),

                // Se for 'warning' ou 'success', a cor do texto fica dark
                ...((ownerState.color === 'warning' || ownerState.color === 'success') && {
                  color: paletteColor.dark
                }),

                '&.MuiChip-clickable': {
                  '&:hover': {
                    color: paletteColor.light,
                    backgroundColor: paletteColor.dark
                  }
                }
              })
            };
          }
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            borderColor: theme.palette.warning.dark,
            color: theme.palette.warning.dark
          }
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            borderColor: theme.palette.success.dark,
            color: theme.palette.success.dark
          }
        }
      ],

      // 2) styleOverrides convencionais ficam aqui
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit'
          }
        }
      }
    }
  };
}

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../contexts/AuthContext";
import MenuDrawer from "./MenuDrawer";

export default function Bipage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [roleId, setRoleId] = useState(null);
  // Inicia com o Drawer aberto em telas maiores que mobile
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />
      {/* AppBar sem margem à esquerda */}
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[2],
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            BI - Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* MenuDrawer */}
      <MenuDrawer
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        roleId={roleId}
      />

      {/* Área Principal (sem margin-left) */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          mt: 8,
          p: 3,
          backgroundColor: "#eef2f6",
          borderRadius: 3,
        }}
      >
        <Typography variant="h4">Conteúdo do BI - Dashboard</Typography>
      </Box>
    </Box>
  );
}

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Link,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Array com imagens do banner – a primeira deve ter name="banner-principal"
const banners = [
  '/assets/images/banner-principal.jpg',
  '/assets/images/banner2.jpg',
  '/assets/images/banner3.jpg',
];

const MaderoLandingPage = () => {
  // Estado para controle do banner ativo
  const [currentBanner, setCurrentBanner] = useState(0);
  // Estado para geolocalização e restaurantes próximos (simulados)
  const [userLocation, setUserLocation] = useState(null);
  const [nearbyRestaurants, setNearbyRestaurants] = useState([]);

  // Função para trocar para o banner anterior
  const handlePrevBanner = () => {
    setCurrentBanner((prev) => (prev === 0 ? banners.length - 1 : prev - 1));
  };

  // Função para trocar para o próximo banner
  const handleNextBanner = () => {
    setCurrentBanner((prev) => (prev === banners.length - 1 ? 0 : prev + 1));
  };

  // Função para buscar geolocalização do usuário e simular listagem de restaurantes
  const handleFindRestaurants = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          // Dados estáticos simulando restaurantes próximos
          setNearbyRestaurants([
            { name: 'Madero Centro', address: 'Rua Exemplo, 123' },
            { name: 'Madero Shopping', address: 'Avenida Exemplo, 456' },
          ]);
        },
        (error) => {
          console.error('Erro ao obter localização:', error);
        }
      );
    } else {
      console.error('Geolocalização não é suportada pelo navegador.');
    }
  };

  return (
    <div>
      {/* Cabeçalho fixo */}
      <AppBar position="fixed" sx={{ backgroundColor: 'black' }}>
        <Toolbar>
          {/* Logo */}
          <Typography variant="h6" sx={{ flexGrow: 1, color: 'white', fontWeight: 'bold' }}>
            MADERO
          </Typography>
          {/* Menu de Navegação */}
          {[
            'Cardápio',
            'Restaurantes',
            'Reservas',
            'Madero Cashback',
            'Cartão Presente',
            'Investidores',
            'Trabalhe Conosco',
            'Peça Aqui Seu Delivery',
          ].map((item, index) => (
            <Link
              key={index}
              href={`#${item.toLowerCase().replace(/\s/g, '-')}`}
              underline="none"
              sx={{
                mx: 1,
                color: 'white',
                fontWeight: 'bold',
                '&:hover': { color: '#D4AF37' },
              }}
            >
              {item}
            </Link>
          ))}
        </Toolbar>
      </AppBar>
      {/* Espaço para compensar o cabeçalho fixo */}
      <Toolbar />

      {/* Banner Principal (Hero Section) */}
      <Box
        id="banner-principal"
        sx={{
          position: 'relative',
          height: { xs: '40vh', md: '60vh' },
          backgroundImage: `url(${banners[currentBanner]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Botão para rotação à esquerda */}
        <IconButton
          onClick={handlePrevBanner}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '20px',
            transform: 'translateY(-50%)',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.3)',
            '&:hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        {/* Botão para rotação à direita */}
        <IconButton
          onClick={handleNextBanner}
          sx={{
            position: 'absolute',
            top: '50%',
            right: '20px',
            transform: 'translateY(-50%)',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.3)',
            '&:hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      {/* Conteúdo principal opcional */}
      <Container sx={{ py: 8 }}>
        {/* Aqui podem ser inseridas outras seções, como Cardápio, Restaurantes, etc. */}
      </Container>

      {/* Rodapé flutuante inferior */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 1,
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        {/* Botão com ícone para buscar restaurantes próximos */}
        <Button
          variant="text"
          onClick={handleFindRestaurants}
          sx={{ color: 'white', textTransform: 'none' }}
          startIcon={<LocationOnIcon />}
        >
          RESTAURANTES PRÓXIMOS
        </Button>
        {/* Texto central */}
        <Typography variant="body2" sx={{ mx: 2, textAlign: 'center' }}>
          Ative sua localização e encontre o Madero mais próximo de você
        </Typography>
        {/* Link para reservas */}
        <Link
          href="https://reserva.grupomadero.com.br/reservation"
          target="_blank"
          rel="noopener"
          sx={{ color: 'white', fontSize: '0.8rem' }}
        >
          https://reserva.grupomadero.com.br/reservation
        </Link>
      </Box>

      {/* Exemplo de exibição da lista de restaurantes próximos (simulada) */}
      {userLocation && nearbyRestaurants.length > 0 && (
        <Container sx={{ mt: 10, mb: 10 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#D4AF37' }}>
            Restaurantes Próximos
          </Typography>
          <Grid container spacing={2}>
            {nearbyRestaurants.map((restaurant, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card sx={{ border: '1px solid #D4AF37' }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {restaurant.name}
                    </Typography>
                    <Typography variant="body2">{restaurant.address}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default MaderoLandingPage;

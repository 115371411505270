import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

// Date Pickers e Charts
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { BarChart } from "@mui/x-charts/BarChart";

// Contexto de Autenticação
import { AuthContext } from "../../contexts/AuthContext";

// Importa o MenuDrawer criado
import MenuDrawer from "./MenuDrawer";

const API_URL = "http://108.165.179.193:8000";
const drawerWidth = 230;

// Lista dos itens alimentares (para os gráficos)
const foodColumns = [
  "ARROZ",
  "FEIJAO",
  "MACARRAO",
  "BATATA FRITA",
  "STROGONOFF",
  "MOLHO VERMELHO",
  "MOLHO BRANCO",
  "PURE",
  "FRANGO MILANESA",
  "BATATA PALHA",
  "QUEIJO",
  "BACON",
  "LOMBO MILANESA",
  "NHOQUE",
  "FRANGO GRELHADO"
];

const secondsToTimeString = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const pad = (n) => String(n).padStart(2, "0");
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export default function EntradaPage() {
  const theme = useTheme();
  // Define breakpoints:
  // isTabletOrMobile: telas até ~1280px (temporary Drawer)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isBelow900 = useMediaQuery(theme.breakpoints.down("md"));
  const isBelow1800 = useMediaQuery("(max-width:1799px)");

  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [roleId, setRoleId] = useState(null);
  // Inicia com o Drawer fechado
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Estados para filtros de data e dados retornados
  const [dateRanges1, setDateRanges1] = useState([null, null]);
  const [dateRanges2, setDateRanges2] = useState([null, null]);
  const [comparisonData, setComparisonData] = useState(null);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isTabletOrMobile) {
      setDrawerOpen(false);
    }
  };

  const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);
  const formatMonth = (date) =>
    date ? format(new Date(date), "LLLL", { locale: ptBR }) : null;

  // Verifica se ambos os intervalos são do mesmo mês e, se sim, adiciona sufixos " 1" e " 2"
  const label1Month = dateRanges1[0] ? formatMonth(dateRanges1[0]) : "Intervalo 1";
  const label2Month = dateRanges2[0] ? formatMonth(dateRanges2[0]) : "Intervalo 2";
  const sameMonth =
    label1Month === label2Month &&
    label1Month !== "Intervalo 1" &&
    label2Month !== "Intervalo 2";
  const label1Text = sameMonth ? `${label1Month} 1` : label1Month;
  const label2Text = sameMonth ? `${label2Month} 2` : label2Month;

  const handleCompareDates = async () => {
    if (!dateRanges1[0] || !dateRanges1[1] || !dateRanges2[0] || !dateRanges2[1]) {
      console.error("⚠️ Selecione todas as datas antes de comparar.");
      return;
    }
    try {
      const token = auth?.token;
      if (!token) {
        console.error("Usuário não autenticado.");
        return;
      }
      const payload = {
        start_date_1: formatDate(dateRanges1[0]),
        end_date_1: formatDate(dateRanges1[1]),
        start_date_2: formatDate(dateRanges2[0]),
        end_date_2: formatDate(dateRanges2[1])
      };
      console.log("📡 Enviando para API (Entrada):", payload);
      const response = await axios.post(
        `${API_URL}/entrada/compare-dates-entrada`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("📊 Dados de Entrada:", response.data);
      setComparisonData(response.data);
    } catch (error) {
      console.error("❌ Erro ao comparar datas (Entrada):", error);
    }
  };

  // Configuração do Grid:
  // Se tela >=1800px, 4 colunas; senão, 3 colunas.
  const gridProps = !isBelow1800 ? { xs: 12, sm: 6, md: 3 } : { xs: 12, sm: 6, md: 4 };

  // Tamanho dinâmico do gráfico:
  let chartWidth = 250;
  let chartHeight = 200;
  if (!isBelow900 && isBelow1800) {
    chartWidth = 300;
    chartHeight = 220;
  } else if (!isBelow1800) {
    chartWidth = 350;
    chartHeight = 250;
  }

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />

      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[2],
          transition: "none"
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, display: isTabletOrMobile && drawerOpen ? "none" : "block" }}
          >
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Entrada - Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* MenuDrawer */}
      <MenuDrawer
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        roleId={roleId}
      />

      {/* Área Principal (sem margin-left) */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          mt: 8,
          p: 3,
          backgroundColor: "#eef2f6",
          borderRadius: 3,
          transition: "none"
        }}
      >
        {/* Caixa de Título e Filtros */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            border: "1px solid #ccc",
            p: 3,
            mb: 3,
            boxShadow: 1,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Entrada - Dashboard
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker
                  label="Data Inicial 1"
                  value={dateRanges1[0]}
                  onChange={(newValue) => setDateRanges1([newValue, dateRanges1[1]])}
                />
                <DatePicker
                  label="Data Final 1"
                  value={dateRanges1[1]}
                  onChange={(newValue) => setDateRanges1([dateRanges1[0], newValue])}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker
                  label="Data Inicial 2"
                  value={dateRanges2[0]}
                  onChange={(newValue) => setDateRanges2([newValue, dateRanges2[1]])}
                />
                <DatePicker
                  label="Data Final 2"
                  value={dateRanges2[1]}
                  onChange={(newValue) => setDateRanges2([dateRanges2[0], newValue])}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={handleCompareDates}>
                Comparar Intervalos de Datas
              </Button>
            </Box>
          </LocalizationProvider>
        </Box>

        {comparisonData ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              Comparação de Somas dos Itens Alimentares
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              {label1Text} vs {label2Text}
            </Typography>

            {/* Grid Responsivo para os Gráficos */}
            <Grid container spacing={2}>
              {foodColumns.map((col) => {
                const value1 = comparisonData?.interval_1?.sums?.[col] || 0;
                const value2 = comparisonData?.interval_2?.sums?.[col] || 0;
                return (
                  <Grid item {...gridProps} key={col}>
                    <Card sx={{ backgroundColor: "#fff", mb: 2, p: 2 }}>
                      <CardContent>
                        <Typography variant="subtitle1" align="center">
                          {col}
                        </Typography>
                        <Box
                          sx={{
                            display: "inline-block",
                            backgroundColor: "#fff",
                            borderRadius: 2,
                            boxShadow: 1,
                            p: 1,
                          }}
                        >
                          <BarChart
                            borderRadius={7}
                            width={chartWidth}
                            height={chartHeight}
                            barLabel={(bar) =>
                              typeof bar.value === "number" ? bar.value.toFixed(1) : "0"
                            }
                            xAxis={[
                              {
                                scaleType: "band",
                                data: [label1Text, label2Text],
                                tick: { style: { fill: theme.palette.text.primary } },
                              },
                            ]}
                            yAxis={[
                              {
                                tick: { style: { fill: theme.palette.text.primary } },
                              },
                            ]}
                            series={[
                              {
                                data: [value1, value2],
                                label: col,
                                color: "#1976d2",
                              },
                            ]}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
            Nenhum dado de entrada para exibir
          </Typography>
        )}
      </Box>
    </Box>
  );
}

// src/theme/ThemeCustomization.jsx
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import getPalette from './palette';
import getShadows from './shadows';
import getTypography from './typography';
import compStyleOverride from './compStyleOverride';

export default function ThemeCustomization({
  children,
  mode = 'light',
  presetColor = 'default'
}) {
  // Carrega a paleta
  const palette = useMemo(() => getPalette(mode, presetColor), [mode, presetColor]);

  // Carrega as sombras
  const shadows = useMemo(() => getShadows(mode), [mode]);

  // Passa palette para a tipografia
  const typography = useMemo(() => getTypography({ palette }), [palette]);

  // Monta o objeto final do tema
  const themeOptions = useMemo(() => {
    return {
      palette,
      shadows,
      typography,
      components: {
        ...compStyleOverride
      }
    };
  }, [palette, shadows, typography]);

  // Cria o theme
  const theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node.isRequired,
  mode: PropTypes.oneOf(['light', 'dark']),
  presetColor: PropTypes.string
};

// RegisterPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const API_URL = 'http://108.165.179.193:8000';

const RegisterPage = () => {
    const { auth, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    // ✅ Estados Gerais
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);

    // ✅ Estados para Atualizar Senha
    const [selectedUser, setSelectedUser] = useState('');
    const [newPassword, setNewPassword] = useState('');

    // ✅ Estados para Excluir Usuário
    const [userToDelete, setUserToDelete] = useState('');

    // ✅ Estados de Feedback
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // ✅ Buscar roles e usuários
    const fetchRolesAndUsers = async () => {
        setError('');
        try {
            if (!auth.token) throw new Error('Token não encontrado. Faça login novamente.');

            const [rolesResponse, usersResponse] = await Promise.all([
                fetch(`${API_URL}/admin/roles`, { headers: { Authorization: `Bearer ${auth.token}` } }),
                fetch(`${API_URL}/admin/users`, { headers: { Authorization: `Bearer ${auth.token}` } }),
            ]);

            if (!rolesResponse.ok || !usersResponse.ok) {
                throw new Error('Erro ao buscar dados do backend.');
            }

            const rolesData = await rolesResponse.json();
            const usersData = await usersResponse.json();

            setRoles(rolesData);
            setUsers(usersData);

            if (rolesData.length > 0) setRole(rolesData[0]?.id || '');
        } catch (err) {
            console.error('🔴 Erro ao buscar dados:', err.message);
            setError(err.message || 'Erro ao carregar dados.');
        }
    };

    // ✅ Carregar dados iniciais
    useEffect(() => {
        if (!auth.token) {
            navigate('/'); // Redireciona para login se não houver token
        } else {
            fetchRolesAndUsers();
        }
    }, [auth.token, navigate]);

    // ✅ Registrar Usuário
    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        try {
            if (!auth.token) throw new Error('Você precisa estar autenticado.');

            const response = await fetch(`${API_URL}/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify({ email, password, role_id: parseInt(role) }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.detail || 'Erro no registro.');
            }

            setSuccess('✅ Usuário registrado com sucesso!');
            setEmail('');
            setPassword('');
            setRole(roles[0]?.id || '');

            await fetchRolesAndUsers(); // Atualizar listas
        } catch (err) {
            console.error('🔴 Erro ao registrar usuário:', err.message);
            setError(err.message || 'Erro no registro.');
        }
    };

    // ✅ Alterar Senha do Usuário
    const handleUpdatePassword = async () => {
        setError('');
        setSuccess('');
        try {
            if (!auth.token) throw new Error('Você precisa estar autenticado.');

            const response = await fetch(`${API_URL}/admin/users/${selectedUser}/password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify({ new_password: newPassword }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.detail || 'Erro ao alterar senha.');
            }

            setSuccess('✅ Senha alterada com sucesso!');
            setSelectedUser('');
            setNewPassword('');

            await fetchRolesAndUsers(); // Atualizar listas
        } catch (err) {
            console.error('🔴 Erro ao alterar senha:', err.message);
            setError(err.message || 'Erro ao alterar senha.');
        }
    };

    // ✅ Excluir Usuário
    const handleDeleteUser = async () => {
        setError('');
        setSuccess('');
        try {
            if (!userToDelete) throw new Error('Selecione um usuário para excluir.');

            const response = await fetch(`${API_URL}/admin/users/${userToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                },
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.detail || 'Erro ao excluir usuário.');
            }

            setSuccess('✅ Usuário excluído com sucesso!');
            setUserToDelete('');

            await fetchRolesAndUsers(); // Atualizar listas
        } catch (err) {
            console.error('🔴 Erro ao excluir usuário:', err.message);
            setError(err.message || 'Erro ao excluir usuário.');
        }
    };

    return (
        <div className="auth-container">
            {/* ✅ Botão de Voltar */}
            <div className="back-button" onClick={() => navigate('/menu')}>
                ← Voltar
            </div>

            <div className="auth-box">
                <h2>Gerenciamento de Usuários</h2>

                {/* ✅ Registrar Usuário */}
                <h3>Registrar Usuário</h3>
                <form onSubmit={handleRegisterSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <select value={role} onChange={(e) => setRole(e.target.value)}>
                        {roles.map((roleItem) => (
                            <option key={roleItem.id} value={roleItem.id}>
                                {roleItem.name}
                            </option>
                        ))}
                    </select>
                    <button type="submit">Registrar</button>
                </form>

                {/* ✅ Alterar Senha */}
                <h3>Alterar Senha</h3>
                <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                    <option value="">Selecione um usuário</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.email}
                        </option>
                    ))}
                </select>
                <input
                    type="password"
                    placeholder="Nova Senha"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <button onClick={handleUpdatePassword}>Alterar Senha</button>

                {/* ✅ Excluir Usuário */}
                <h3>Excluir Usuário</h3>
                <select value={userToDelete} onChange={(e) => setUserToDelete(e.target.value)}>
                    <option value="">Selecione um usuário</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.email}
                        </option>
                    ))}
                </select>
                <button onClick={handleDeleteUser}>Excluir Usuário</button>

                {/* ✅ Mensagens de Erro e Sucesso */}
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
            </div>
        </div>
    );
};

export default RegisterPage;

// src/theme/shadows.jsx
import { alpha } from '@mui/material/styles';

function createCustomShadow(theme, baseColor) {
  const transparent = alpha(baseColor, 0.24);
  return {
    z1: `0 1px 2px 0 ${transparent}`,
    z8: `0 8px 16px 0 ${transparent}`,
    z12: `0 12px 24px 0 ${transparent} 0 10px 20px 0 ${transparent}`,
    z16: `0 0 3px 0 ${transparent} 0 14px 28px -5px ${transparent}`,
    z20: `0 0 3px 0 ${transparent} 0 18px 36px -5px ${transparent}`,
    z24: `0 0 6px 0 ${transparent} 0 21px 44px 0 ${transparent}`,

    primary: `0 12px 14px 0 ${alpha(theme.palette.primary.main, 0.3)}`,
    secondary: `0 12px 14px 0 ${alpha(theme.palette.secondary.main, 0.3)}`,
    orange: `0 12px 14px 0 ${alpha(theme.palette?.orange?.main || '#ffa726', 0.3)}`,
    success: `0 12px 14px 0 ${alpha(theme.palette.success.main, 0.3)}`,
    warning: `0 12px 14px 0 ${alpha(theme.palette.warning.main, 0.3)}`,
    error: `0 12px 14px 0 ${alpha(theme.palette.error.main, 0.3)}`
  };
}

export default function customShadows(theme) {
  // Se o tema ou a paleta não estiverem definidos, retorne um objeto vazio
  if (!theme || !theme.palette) {
    return {};
  }

  // Define a cor base para as sombras; usamos grey[900] se disponível, caso contrário um fallback preto
  const baseColor = (theme.palette.grey && theme.palette.grey[900]) || '#000000';

  // Retorna o objeto de sombras personalizadas
  return createCustomShadow(theme, baseColor);
}

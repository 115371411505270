import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  Divider,
  GlobalStyles,
  Avatar,
  Typography,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import "@fontsource/inter";

import logo from "../../assets/images/logo-200px.png";
import RoboIcon from "../../assets/images/robo.svg";
import GraficoIcon from "../../assets/images/grafico.svg";
import ProducaoIcon from "../../assets/images/producao.svg";
import UserIcon from "../../assets/images/user.svg";

const drawerWidth = 230;

const DrawerItem = ({ icon, text, path, active, onClick, pl = 2, iconRight }) => {
  const theme = useTheme();
  return (
    <ListItem
      button
      onClick={onClick}
      sx={{
        transition: "all 0.3s ease",
        cursor: "pointer",
        borderRadius: "10px",
        px: 2,
        mb: 0.5,
        pl,
        borderLeft: active ? `4px solid ${theme.palette.primary.main}` : '4px solid transparent',
        backgroundColor: active ? alpha(theme.palette.primary.main, 0.15) : "transparent",
        boxShadow: active ? theme.shadows[2] : "none",
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          boxShadow: theme.shadows[1],
        },
        color: active ? theme.palette.primary.main : theme.palette.grey[700],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: "'Inter', sans-serif",
      }}
    >
      <Box display="flex" alignItems="center">
        {icon && <ListItemIcon sx={{ minWidth: 32 }}>{icon}</ListItemIcon>}
        <ListItemText primary={text} primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }} />
      </Box>
      {iconRight && <Box sx={{ ml: 1 }}>{iconRight}</Box>}
    </ListItem>
  );
};

const MenuDrawer = ({ drawerOpen, handleDrawerToggle, roleId }) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const [biOpen, setBiOpen] = useState(false);
  const [operacaoOpen, setOperacaoOpen] = useState(false);
  const [receitaOpen, setReceitaOpen] = useState(false);

  useEffect(() => {
    if (["/marketing", "/delivery", "/entrada", "/saida", "/faltas", "/faturamento", "/pratos"].includes(currentPath)) {
      setBiOpen(true);
    }
    if (["/delivery", "/entrada", "/saida", "/faltas"].includes(currentPath)) {
      setOperacaoOpen(true);
    }
    if (["/faturamento", "/pratos"].includes(currentPath)) {
      setReceitaOpen(true);
    }
  }, [currentPath]);

  const handleNavigation = (path) => {
    navigate(path);
    if (isTabletOrMobile) handleDrawerToggle();
  };

  return (
    <>
      <GlobalStyles
        styles={{
          "*": { fontFamily: "'Inter', sans-serif" },
          ".css-1y98zvy": { backgroundColor: "#EEF2F6 !important" },
        }}
      />
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant={isTabletOrMobile ? "temporary" : "persistent"}
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "#FFFFFF",
              overflowY: "auto",
              boxShadow: theme.shadows[3],
              borderRadius: "0px 20px 20px 0px",
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
              <Box component="img" src={logo} alt="Logo" sx={{ width: "70%" }} />
            </Box>

            <Box display="flex" alignItems="center" gap={1} mb={2} ml={1}>
              <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
              <Typography variant="body2">Admin</Typography>
            </Box>

            <List sx={{ px: 1 }}>
              <DrawerItem
                icon={<Box component="img" src={RoboIcon} alt="IA de Caixa" sx={{ width: 20, height: 20 }} />}
                text="IA de Caixa"
                path="/LojaIA"
                active={currentPath === "/LojaIA"}
                onClick={() => handleNavigation("/LojaIA")}
              />

              <Divider sx={{ my: 1 }} />

              <DrawerItem
                icon={<Box component="img" src={ProducaoIcon} alt="Produção" sx={{ width: 20, height: 20 }} />}
                text="Produção"
                path="/producao"
                active={currentPath === "/producao"}
                onClick={() => handleNavigation("/producao")}
              />

              <Divider sx={{ my: 1 }} />

              <DrawerItem
                icon={<Box component="img" src={GraficoIcon} alt="B.I" sx={{ width: 20, height: 20 }} />}
                text="B.I"
                active={false}
                onClick={() => setBiOpen(!biOpen)}
                iconRight={<ExpandMore sx={{ transform: biOpen ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.3s ease" }} />}
              />

              <Collapse in={biOpen} timeout={250} unmountOnExit>
                <List component="div" disablePadding>
                  <DrawerItem text="Marketing" path="/marketing" active={currentPath === "/marketing"} onClick={() => handleNavigation("/marketing")} pl={4} />

                  <DrawerItem
                    text="Operação"
                    active={false}
                    onClick={() => setOperacaoOpen(!operacaoOpen)}
                    pl={4}
                    iconRight={<ExpandMore sx={{ transform: operacaoOpen ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.3s ease" }} />}
                  />

                  <Collapse in={operacaoOpen} timeout={250} unmountOnExit>
                    <List component="div" disablePadding>
                      <DrawerItem text="Delivery" path="/delivery" active={currentPath === "/delivery"} onClick={() => handleNavigation("/delivery")} pl={6} />
                      <DrawerItem text="Entrada" path="/entrada" active={currentPath === "/entrada"} onClick={() => handleNavigation("/entrada")} pl={6} icon={<CallReceivedIcon sx={{ fontSize: 18 }} />} />
                      <DrawerItem text="Saída" path="/saida" active={currentPath === "/saida"} onClick={() => handleNavigation("/saida")} pl={6} icon={<CallMadeIcon sx={{ fontSize: 18 }} />} />
                      <DrawerItem text="Faltas" path="/faltas" active={currentPath === "/faltas"} onClick={() => handleNavigation("/faltas")} pl={6} icon={<ReportProblemIcon sx={{ fontSize: 18 }} />} />
                    </List>
                  </Collapse>

                  <DrawerItem
                    text="Receita"
                    active={false}
                    onClick={() => setReceitaOpen(!receitaOpen)}
                    pl={4}
                    iconRight={<ExpandMore sx={{ transform: receitaOpen ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.3s ease" }} />}
                  />

                  <Collapse in={receitaOpen} timeout={250} unmountOnExit>
                    <List component="div" disablePadding>
                      <DrawerItem text="Faturamento" path="/faturamento" active={currentPath === "/faturamento"} onClick={() => handleNavigation("/faturamento")} pl={6} />
                      <DrawerItem text="Pratos" path="/pratos" active={currentPath === "/pratos"} onClick={() => handleNavigation("/pratos")} pl={6} />
                    </List>
                  </Collapse>
                </List>
              </Collapse>

              {roleId === 1 && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <DrawerItem
                    icon={<Box component="img" src={UserIcon} alt="Registrar Usuário" sx={{ width: 20, height: 20 }} />}
                    text="Registrar Usuário"
                    path="/register"
                    active={currentPath === "/register"}
                    onClick={() => handleNavigation("/register")}
                  />
                </>
              )}
            </List>
          </Box>
        </Drawer>

        {/* Espaçamento entre o menu e o conteúdo com sombra */}
        <Box sx={{ width: 10, flexShrink: 0, boxShadow: theme.shadows[1], backgroundColor: 'transparent' }} />
      </Box>
    </>
  );
};

export default MenuDrawer;
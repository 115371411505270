import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

// Date Pickers e Charts
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { BarChart } from "@mui/x-charts/BarChart";

// Contexto de Autenticação e MenuDrawer
import { AuthContext } from "../../contexts/AuthContext";
import MenuDrawer from "./MenuDrawer";

const API_URL = "http://108.165.179.193:8000";

// Array de produtos/métricas
const products = [
  { key: "gratans", label: "Gratans" },
  { key: "strogonoffs_de_frango", label: "Strogonoffs Frango" },
  { key: "parmegianas", label: "Parmegianas" },
  { key: "strogonoffs", label: "Strogonoffs" },
  { key: "tradicionais", label: "Tradicionais" },
  { key: "saladas_de_frango", label: "Saladas Frango" },
  { key: "talharins", label: "Talharins" },
  { key: "pudins", label: "Pudins" },
  { key: "charges", label: "Charges" },
  { key: "chas", label: "Chás" },
  { key: "acompanhamentos_talharim", label: "Acomp. Talharim" },
  { key: "acompanhamentos_arroz_batata_chica", label: "Acomp. Arroz/Batata Chica" },
  { key: "acompanhamentos_pure", label: "Acomp. Purê" },
  { key: "acompanhamentos_arroz_batata_palha", label: "Acomp. Arroz/Batata Palha" },
  { key: "acompanhamentos_arroz_pure", label: "Acomp. Arroz/Pure" },
  { key: "acompanhamentos_arroz_feijao_pure", label: "Acomp. Arroz/Feijão/Purê" },
  { key: "acompanhamentos_arroz_feijao_talharim", label: "Acomp. Arroz/Feijão/Talharim" },
  { key: "acompanhamentos_arroz_feijao_batata_chica", label: "Acomp. Arroz/Feijão/Batata Chica" },
  { key: "frangos_a_milanesa", label: "Frangos Milanesa" },
  { key: "lombos_a_milanesa", label: "Lombos Milanesa" },
  { key: "frangos_grelhados", label: "Frangos Grelhados" }
];

// Função para formatar o mês por extenso
const formatMonthName = (date) =>
  date ? format(new Date(date), "MMMM", { locale: ptBR }) : "";

export default function PratosPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isBelow1800 = useMediaQuery("(max-width:1799px)");

  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  // Estados de autenticação, filtros e dados
  const [roleId, setRoleId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [dateRanges1, setDateRanges1] = useState([null, null]);
  const [dateRanges2, setDateRanges2] = useState([null, null]);
  const [comparisonData, setComparisonData] = useState(null);

  // Estados para o popup (histórico mensal)
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [popupTitle, setPopupTitle] = useState("");
  const [selectedMetric, setSelectedMetric] = useState(null);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Formatação de datas para o padrão ISO
  const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);

  // Comparação de intervalos de datas
  const handleCompareDates = async () => {
    if (!dateRanges1[0] || !dateRanges1[1] || !dateRanges2[0] || !dateRanges2[1]) return;
    try {
      const payload = {
        start_date_1: formatDate(dateRanges1[0]),
        end_date_1: formatDate(dateRanges1[1]),
        start_date_2: formatDate(dateRanges2[0]),
        end_date_2: formatDate(dateRanges2[1])
      };
      const response = await axios.post(`${API_URL}/pratos/compare-dates-produtos`, payload, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json"
        }
      });
      setComparisonData(response.data);
    } catch (err) {
      console.error("Erro ao comparar datas:", err);
    }
  };

  // Ao clicar no gráfico, busca o histórico mensal para a métrica selecionada
  const handleOpenPopup = async (metricKey, label) => {
    try {
      const response = await axios.get(`${API_URL}/faturamento-year-summary?metric=${metricKey}`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setPopupData(response.data);
      setPopupTitle(label);
      setSelectedMetric(metricKey);
      setOpenPopup(true);
    } catch (err) {
      console.error("Erro ao buscar resumo anual:", err);
    }
  };

  // Configuração dos rótulos dos intervalos de data
  const label1Text = dateRanges1[0] ? formatMonthName(dateRanges1[0]) : "Intervalo 1";
  const label2Text = dateRanges2[0] ? formatMonthName(dateRanges2[0]) : "Intervalo 2";
  const sameMonth =
    label1Text === label2Text && label1Text !== "Intervalo 1" && label2Text !== "Intervalo 2";
  const finalLabel1 = sameMonth ? `${label1Text} 1` : label1Text;
  const finalLabel2 = sameMonth ? `${label2Text} 2` : label2Text;

  // Configuração do grid para responsividade
  const gridProps = !isBelow1800 ? { xs: 12, sm: 6, md: 3 } : { xs: 12, sm: 6, md: 4 };

  // Tamanho dos gráficos baseado no tamanho da tela
  let chartWidth = 250;
  let chartHeight = 200;
  if (!isMdDown && isBelow1800) {
    chartWidth = 300;
    chartHeight = 220;
  } else if (!isBelow1800) {
    chartWidth = 350;
    chartHeight = 250;
  }

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[2]
        }}
      >
        <Toolbar>
          <IconButton color="inherit" onClick={handleDrawerToggle} edge="start" sx={{ mr: 2 }}>
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="h6" noWrap>
            📊 Pratos - Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <MenuDrawer drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} roleId={roleId} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          mt: 8,
          p: 3,
          backgroundColor: "#eef2f6",
          borderRadius: 3
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            border: "1px solid #ccc",
            p: 3,
            mb: 3,
            boxShadow: 1
          }}
        >
          <Typography variant="h5" gutterBottom>
            📊 Pratos - Dashboard
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker
                  label="Data Inicial 1"
                  value={dateRanges1[0]}
                  onChange={(newValue) => setDateRanges1([newValue, dateRanges1[1]])}
                />
                <DatePicker
                  label="Data Final 1"
                  value={dateRanges1[1]}
                  onChange={(newValue) => setDateRanges1([dateRanges1[0], newValue])}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker
                  label="Data Inicial 2"
                  value={dateRanges2[0]}
                  onChange={(newValue) => setDateRanges2([newValue, dateRanges2[1]])}
                />
                <DatePicker
                  label="Data Final 2"
                  value={dateRanges2[1]}
                  onChange={(newValue) => setDateRanges2([dateRanges2[0], newValue])}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={handleCompareDates}>
                Comparar Intervalos de Datas
              </Button>
            </Box>
          </LocalizationProvider>
        </Box>

        {comparisonData ? (
          <Grid container spacing={2}>
            {products.map((product) => {
              const value1 = comparisonData.interval_1["average_" + product.key] || 0;
              const value2 = comparisonData.interval_2["average_" + product.key] || 0;
              const percentDiff =
                value1 === 0 ? (value2 === 0 ? "0.0%" : "N/A") : (((value2 - value1) / value1) * 100).toFixed(1) + "%";

              return (
                <Grid item {...gridProps} key={product.key}>
                  <Card
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 2,
                      border: "1px solid #ccc",
                      boxShadow: 1,
                      p: 2,
                      maxWidth: 420,
                      mx: "auto",
                      cursor: "pointer"
                    }}
                    onClick={() => handleOpenPopup(product.key, product.label)}
                  >
                    <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
                      <BarChart
                        borderRadius={7}
                        width={chartWidth}
                        height={chartHeight}
                        barLabel={(bar) =>
                          typeof bar.value === "number" ? bar.value.toFixed(1) : "0"
                        }
                        xAxis={[{ scaleType: "band", data: [finalLabel1, finalLabel2] }]}
                        yAxis={[{}]}
                        series={[
                          { data: [value1, value2], label: product.label, color: "#1976d2" }
                        ]}
                      />
                      <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                        Diferença: {percentDiff}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
            Nothing to display
          </Typography>
        )}

        <Dialog open={openPopup} onClose={() => setOpenPopup(false)} maxWidth="md" fullWidth>
          <DialogTitle>Resumo do Período - {popupTitle}</DialogTitle>
          <DialogContent>
            {popupData && selectedMetric ? (
              <Box>
                <BarChart
                  width={600}
                  height={300}
                  borderRadius={7}
                  barLabel={(bar) =>
                    typeof bar.value === "number" ? bar.value.toFixed(2) : "0"
                  }
                  xAxis={[
                    {
                      scaleType: "band",
                      data: popupData.summary.map((item) => item.month),
                      tick: { style: { fill: theme.palette.text.primary } }
                    }
                  ]}
                  yAxis={[
                    { tick: { style: { fill: theme.palette.text.primary } } }
                  ]}
                  series={[
                    {
                      data: popupData.summary.map((item) => item[selectedMetric]),
                      label: popupTitle
                    }
                  ]}
                />
              </Box>
            ) : (
              <Typography>Nenhum dado encontrado.</Typography>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

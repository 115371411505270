import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

// Date Pickers
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

// Contexto de Autenticação
import { AuthContext } from "../../contexts/AuthContext";

// Menu lateral
import MenuDrawer from "./MenuDrawer";

// Imagens (se necessário)
import logo from "../../assets/images/logo-200px.png";
import RoboIcon from "../../assets/images/robo.svg";
import GraficoIcon from "../../assets/images/grafico.svg";
import UserIcon from "../../assets/images/user.svg";
import ProducaoIcon from "../../assets/images/producao.svg";

const API_URL = "http://108.165.179.193:8000";
const drawerWidth = 230;

// Definindo as métricas (colunas de frases) conforme a planilha
const metrics = [
  { key: "checklist_problems", label: "HOJE TEVE PROBLEMAS DE CHECKLIST?" },
  { key: "checklist_issue", label: "O que aconteceu que não foi feito o checklist?" },
  { key: "team_behavior", label: "COMO FOI O COMPORTAMENTO DA EQUIPE NO DIA DE HOJE?" },
  { key: "problem_details", label: "HOUVE ALGUM PROBLEMA COM: INFORMÁTICA, SISTEMA, TAA, KDS, IMPRESSORAS, SISTEMA APP'S - DESCREVER O QUE." },
  { key: "client_complaints", label: "HOUVE RECLAMAÇÕES DE CLIENTES? SE SIM, QUAIS?" },
  { key: "assistance_requested", label: "FOI NECESSÁRIO SOLICITAR AJUDA? (na montagem, atendimento, etc...)" },
  { key: "logistics_issues", label: "HOUVERAM PROBLEMAS COM A LOGÍSTICA E ENTREGADORES?" },
  { key: "entry_compliance", label: "ELES ESTÃO RESPEITANDO A ENTRADA SEM MOCHILA E CAPACETE? (Solicitar educadamente, caso não atendam)" },
  { key: "absence_info", label: "TEVE FALTA? NOME-MOTIVO" }
];

export default function FaltasPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [roleId, setRoleId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  // Utilizaremos um intervalo de datas: data inicial e data final
  const [dateRange, setDateRange] = useState([null, null]);
  const [comparisonData, setComparisonData] = useState(null);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);
  const formatMonth = (date) =>
    date ? format(new Date(date), "LLLL", { locale: ptBR }) : null;

  const labelStart = dateRange[0] ? formatMonth(dateRange[0]) : "Data Inicial";
  const labelEnd = dateRange[1] ? formatMonth(dateRange[1]) : "Data Final";

  const handleCompareDates = async () => {
    if (!dateRange[0] || !dateRange[1]) {
      console.error("⚠️ Selecione a data inicial e a data final.");
      return;
    }
    try {
      const token = auth?.token;
      if (!token) {
        console.error("Usuário não autenticado.");
        return;
      }
      // Envia o payload com data inicial e data final
      const payload = {
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1])
      };
      console.log("📡 Enviando para API (Faltas):", payload);
      const response = await axios.post(
        `${API_URL}/faltas/compare-dates-faltas`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json"
          }
        }
      );
      console.log("📊 Dados de Faltas:", response.data);
      setComparisonData(response.data);
    } catch (error) {
      console.error("❌ Erro ao buscar dados de faltas:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />

      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[2],
          transition: "none"
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="h6" noWrap>
            📊 Faltas - Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* MenuDrawer */}
      <MenuDrawer
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        roleId={roleId}
      />

      {/* Área Principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          mt: 8,
          p: 3,
          backgroundColor: "#eef2f6",
          borderRadius: 3,
          transition: "none"
        }}
      >
        {/* Caixa de Filtros */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            border: "1px solid #ccc",
            p: 3,
            mb: 3,
            boxShadow: 1,
          }}
        >
          <Typography variant="h5" gutterBottom>
            📊 Faltas - Dashboard
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ mb: 3, display: "flex", gap: 2, alignItems: "center" }}>
              <DatePicker
                label="Data Inicial"
                value={dateRange[0]}
                onChange={(newValue) =>
                  setDateRange([newValue, dateRange[1]])
                }
              />
              <DatePicker
                label="Data Final"
                value={dateRange[1]}
                onChange={(newValue) =>
                  setDateRange([dateRange[0], newValue])
                }
              />
              <Button variant="contained" color="primary" onClick={handleCompareDates}>
                Buscar Dados
              </Button>
            </Box>
          </LocalizationProvider>
          <Typography variant="subtitle1">
            Período: {labelStart} a {labelEnd}
          </Typography>
        </Box>

        {comparisonData ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Registros para o período: {labelStart} a {labelEnd}
            </Typography>
            {comparisonData.records && comparisonData.records.length > 0 ? (
              <Grid container spacing={2}>
                {comparisonData.records.map((record, index) => (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ mb: 2 }}>
                      <CardContent>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                          Data: {record.data}
                        </Typography>
                        {metrics.map((metric) => (
                          <Box key={metric.key} sx={{ mb: 1 }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                              {metric.label}
                            </Typography>
                            <Typography variant="body2">
                              {record[metric.key] || "Sem informação"}
                            </Typography>
                          </Box>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body2">Nenhum registro encontrado para este período.</Typography>
            )}
          </Box>
        ) : (
          <Typography variant="body1">Nenhum dado para exibir</Typography>
        )}
      </Box>
    </Box>
  );
}

// src/theme/palette.jsx

import { createTheme } from '@mui/material/styles';
// import do scss
import defaultColor from './assets/scss/_themes-vars.module.scss';

export default function getPalette(mode = 'light', presetColor = 'default') {
  // Carrega as cores do SCSS (que contêm várias variáveis)
  let colors = defaultColor; 
  // se tiver mais presets, faça switch se quiser

  if (mode === 'light') {
    // Força o modo claro com background claro
    return {
      mode: 'light',
      common: {
        black: '#000' // ou colors.darkPaper, se quiser
      },
      primary: {
        // pode usar as variáveis do SCSS ou valores fixos
        light: colors.primaryLight,
        main: colors.primaryMain,  
        dark: colors.primaryDark
      },
      secondary: {
        light: colors.secondaryLight,
        main: colors.secondaryMain,
        dark: colors.secondaryDark
      },
      background: {
        paper: '#eef2f6',   // fundo dos cards, drawers, etc.
        default: '#eef2f6' // fundo principal da página
      },
      text: {
        primary: '#000000',
        secondary: '#5f6368'
      },
      // etc. (caso queira personalizar mais)
    };
  } else {
    // Força o modo escuro
    return {
      mode: 'dark',
      primary: {
        light: colors.primaryLight,
        main: colors.primaryMain,
        dark: colors.primaryDark
      },
      background: {
        paper: '#1e1e1e',
        default: '#121212'
      },
      text: {
        primary: '#ffffff',
        secondary: '#b0bec5'
      },
      // etc.
    };
  }
}

// src/theme/typography.jsx
export default function Typography(theme, borderRadius, fontFamily) {
    // Cria valores de fallback para a paleta de cinzas e textos
    const grey = (theme && theme.palette && theme.palette.grey) || { 100: "#f5f5f5", 500: "#9e9e9e", 900: "#212121" };
    const textPrimary = (theme && theme.palette && theme.palette.text && theme.palette.text.primary) || grey[900];
    const textSecondary = (theme && theme.palette && theme.palette.text && theme.palette.text.secondary) || grey[500];
    const textDark = (theme && theme.palette && theme.palette.text && theme.palette.text.dark) || grey[900];
  
    return {
      fontFamily,
      h6: {
        fontWeight: 500,
        color: grey[900],
        fontSize: '0.75rem'
      },
      h5: {
        fontSize: '0.875rem',
        color: grey[900],
        fontWeight: 500
      },
      h4: {
        fontSize: '1rem',
        color: grey[900],
        fontWeight: 600
      },
      h3: {
        fontSize: '1.25rem',
        color: grey[900],
        fontWeight: 600
      },
      h2: {
        fontSize: '1.5rem',
        color: grey[900],
        fontWeight: 700
      },
      h1: {
        fontSize: '2.125rem',
        color: grey[900],
        fontWeight: 700
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: textDark
      },
      subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: textSecondary
      },
      caption: {
        fontSize: '0.75rem',
        color: textSecondary,
        fontWeight: 400
      },
      body1: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.334em'
      },
      body2: {
        letterSpacing: '0em',
        fontWeight: 400,
        lineHeight: '1.5em',
        color: textPrimary
      },
      button: {
        textTransform: 'capitalize'
      },
      customInput: {
        marginTop: 1,
        marginBottom: 1,
        '& > label': {
          top: 23,
          left: 0,
          color: grey[500],
          '&[data-shrink="false"]': {
            top: 5
          }
        },
        '& > div > input': {
          padding: '30.5px 14px 11.5px !important'
        },
        '& legend': {
          display: 'none'
        },
        '& fieldset': {
          top: 0
        }
      },
      mainContent: {
        backgroundColor: grey[100],
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: `${borderRadius}px`
      },
      menuCaption: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: grey[900],
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px'
      },
      subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500,
        color: textSecondary,
        textTransform: 'capitalize'
      },
      commonAvatar: {
        cursor: 'pointer',
        borderRadius: '8px'
      },
      smallAvatar: {
        width: '22px',
        height: '22px',
        fontSize: '1rem'
      },
      mediumAvatar: {
        width: '34px',
        height: '34px',
        fontSize: '1.2rem'
      },
      largeAvatar: {
        width: '44px',
        height: '44px',
        fontSize: '1.5rem'
      }
    };
  }
  
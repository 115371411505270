import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Container,
  InputAdornment,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import logo from '../../assets/images/logo-200px.png';

const EmailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="8" r="4" stroke="currentColor" strokeWidth="2" />
    <path d="M4 20c0-4 4-6 8-6s8 2 8 6" stroke="currentColor" strokeWidth="2" fill="none" />
  </svg>
);

const LockIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect x="6" y="11" width="12" height="10" rx="2" stroke="currentColor" strokeWidth="2" />
    <path d="M9 11V7a3 3 0 0 1 6 0v4" stroke="currentColor" strokeWidth="2" fill="none" />
  </svg>
);

const API_URL = 'http://108.165.179.193:8000';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        login(data.access_token);
        navigate('/menu');
      } else {
        const errorMsg = (typeof data === 'object' && (data.detail || JSON.stringify(data))) || 'Erro ao efetuar login';
        setError(errorMsg);
      }
    } catch (err) {
      setError('Não foi possível conectar ao servidor');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: isMobile ? 'column' : 'row' }}>
      {/* LADO ESQUERDO - EXIBIDO APENAS EM TELAS MAIORES */}
      {!isMobile && (
        <Box
          sx={{
            flex: 1,
            backgroundColor: '#ffa400',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Box component="img" src={logo} alt="Logo" sx={{ width: '180px', mb: 2 }} />
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              color: '#fff',
              fontFamily: 'Arial, sans-serif',
              textAlign: 'center',
            }}
          >
            Problemas com sua conta?
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#e69500',
              color: '#fff',
              textTransform: 'none',
              '&:hover': { backgroundColor: '#d17f00' },
            }}
          >
            Acessar Suporte →
          </Button>
        </Box>
      )}

      {/* LADO DIREITO: Formulário */}
      <Box
        sx={{
          flex: 1,
          backgroundColor: '#121317',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          color: '#fff',
          p: 3,
        }}
      >
        <Container maxWidth="xs">
          {isMobile && (
            <Box component="img" src={logo} alt="Logo" sx={{ width: '180px', mx: 'auto', mb: 4 }} />
          )}

          <Typography
            variant="h3"
            sx={{
              textAlign: 'center',
              mb: 1,
              fontWeight: 'bold',
              color: '#fff',
            }}
          >
            Entrar no sistema
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              mb: 3,
              color: '#bbb',
            }}
          >
            Preencha suas credenciais
          </Typography>

          {error && (
            <Typography sx={{ color: 'red', mb: 2, textAlign: 'center' }}>
              {typeof error === 'object' ? error.detail || JSON.stringify(error) : error}
            </Typography>
          )}

          <Box component="form" onSubmit={handleLoginSubmit}>
            {/* Email */}
            <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
              Email
            </Typography>
            <TextField
              fullWidth
              placeholder="Digite seu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
                style: { paddingLeft: '12px' },
              }}
              sx={{
                mb: 2,
                '& .MuiInputBase-input': { color: '#fff', fontSize: '1rem' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#444' },
                  '&:hover fieldset': { borderColor: '#ffa400' },
                  '&.Mui-focused fieldset': { borderColor: '#ffa400' },
                },
                '& .MuiInputBase-input::placeholder': {
                  opacity: 1,
                  color: '#bbb',
                },
              }}
              required
            />

            {/* Senha */}
            <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
              Senha
            </Typography>
            <TextField
              fullWidth
              type="password"
              placeholder="*****"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                style: { paddingLeft: '12px' },
              }}
              sx={{
                mb: 2,
                '& .MuiInputBase-input': { color: '#fff', fontSize: '1rem' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#444' },
                  '&:hover fieldset': { borderColor: '#ffa400' },
                  '&.Mui-focused fieldset': { borderColor: '#ffa400' },
                },
                '& .MuiInputBase-input::placeholder': {
                  opacity: 1,
                  color: '#bbb',
                },
              }}
              required
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Link href="#" variant="body2" sx={{ color: '#ffa400' }}>
                Esqueceu a senha?
              </Link>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: '#ffa400',
                color: '#121317',
                fontWeight: 'bold',
                fontSize: '0.85rem',
                py: 1,
                '&:hover': { backgroundColor: '#e69500' },
              }}
              disabled={isLoading}
            >
              {isLoading ? 'Carregando...' : 'Entrar'}
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LoginPage;

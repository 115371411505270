// src/components/pages/MainMenu.jsx

import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI Imports
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Ícones do MUI
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

// Contexto de Autenticação
import { AuthContext } from "../../contexts/AuthContext";

// Logo e imagens de ícones customizados
import logo from "../../assets/images/logo-200px.png";
import RoboIcon from "../../assets/images/robo.svg";       // IA de Caixa
import GraficoIcon from "../../assets/images/grafico.svg";   // B.I
import ProducaoIcon from "../../assets/images/producao.svg"; // Ícone para Produção

const drawerWidth = 230;

export default function MainMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [roleId, setRoleId] = useState(null);
  // Em mobile, iniciamos com o menu fechado
  const [open, setOpen] = useState(!isMobile);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  // Estilos de hover para os itens de menu com cursor pointer e fundo customizado
  const listItemHoverStyles = {
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(238, 242, 246)",
      transform: "scale(1.02)",
      boxShadow: theme.shadows[4]
    }
  };

  // Conteúdo do Drawer (menu lateral)
  const drawerContent = (
    <Box sx={{ p: 2 }}>
      {/* Logo: grudado à esquerda com 70% de largura */}
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Logo"
          sx={{ width: "70%", ml: 0 }}
        />
        {/* Botão para esconder o menu */}
        <IconButton onClick={handleDrawerToggle}>
          <ChevronLeftIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>

      <List>
        {/* IA de Caixa */}
        <ListItem
          button
          onClick={() => handleNavigation("/LojaIA")}
          sx={listItemHoverStyles}
        >
          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
            <Box
              component="img"
              src={RoboIcon}
              alt="IA de Caixa"
              sx={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText primary="IA de Caixa" />
        </ListItem>

        {/* B.I - exibido somente para administradores */}
        {roleId === 1 && (
          <ListItem
            button
            onClick={() => handleNavigation("/bipage")}
            sx={listItemHoverStyles}
          >
            <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
              <Box
                component="img"
                src={GraficoIcon}
                alt="B.I"
                sx={{ width: 20, height: 20 }}
              />
            </ListItemIcon>
            <ListItemText primary="B.I" />
          </ListItem>
        )}

        {/* Produção */}
        <ListItem
          button
          onClick={() => handleNavigation("/producao")}
          sx={listItemHoverStyles}
        >
          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
            <Box
              component="img"
              src={ProducaoIcon}
              alt="Produção"
              sx={{ width: 20, height: 20 }}
            />
          </ListItemIcon>
          <ListItemText primary="Produção" />
        </ListItem>

        {/* Registrar Usuário - exibido somente para administradores */}
        {roleId === 1 && (
          <ListItem
            button
            onClick={() => handleNavigation("/register")}
            sx={listItemHoverStyles}
          >
            <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
              <PersonAddIcon sx={{ fontSize: 20 }} />
            </ListItemIcon>
            <ListItemText primary="Registrar Usuário" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />

      {/* AppBar Superior */}
      <AppBar
        position="fixed"
        sx={{
          width: !isMobile && open ? `calc(100% - ${drawerWidth}px)` : "100%",
          ml: !isMobile && open ? `${drawerWidth}px` : 0,
          backgroundColor: "#fff",
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[2]
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              mr: 2,
              display: !isMobile && open ? "none" : "block"
            }}
          >
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="h6" noWrap>
            Ferramentas Vó Chica
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            color="secondary"
            onClick={logout}
            sx={{
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
                transform: "scale(1.05)",
                boxShadow: theme.shadows[4]
              }
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Drawer Lateral */}
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            height: isMobile ? "100vh" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            marginTop: 0,
            boxSizing: "border-box",
            backgroundColor: "#fff",
            color: theme.palette.text.primary,
            borderRight: "none",
            boxShadow: "none"
          }
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Área Principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          m: isMobile ? 1 : 3,
          borderRadius: 3,
          backgroundColor: "#eef2f6",
          mt: { xs: 8, sm: 8 },
          p: 3
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            border: "1px solid #ccc",
            p: 3,
            mb: 3,
            boxShadow: 1
          }}
        >
          <Typography variant="h5" gutterBottom>
            Bem-vindo às Ferramentas Vó Chica
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Selecione uma opção no menu ao lado para começar.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

// IMPORTS
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { BarChart } from "@mui/x-charts/BarChart";
import { AuthContext } from "../../contexts/AuthContext";
import MenuDrawer from "./MenuDrawer";

const API_URL = "http://108.165.179.193:8000";
const drawerWidth = 230;

const metrics = [
  { key: "faturamento", label: "Faturamento Geral" },
  { key: "buffet", label: "Faturamento do Buffet" },
  { key: "caixa", label: "Faturamento do Caixa" },
  { key: "delivery", label: "Faturamento do Delivery" },
  { key: "delivery_p10", label: "Faturamento Delivery P10" },
  { key: "delivery_ifood", label: "Faturamento Delivery IFOOD" },
  { key: "delivery_anotaai", label: "Faturamento Delivery ANOTAAI" },
  { key: "total_cupons", label: "Total de Cupons" },
  { key: "cupons_buffet", label: "Cupons do Buffet" },
  { key: "cupons_caixa", label: "Cupons do Caixa" },
  { key: "cupons_delivery", label: "Cupons do Delivery" },
  { key: "tc_retirada", label: "TC RETIRADA" },
  { key: "tc_so_de_pratos", label: "TC SÓ DE PRATOS" },
  { key: "to_go", label: "TO GO" },
];

const formatMonth = (date) =>
  date ? format(new Date(date), "LLLL", { locale: ptBR }) : null;

export default function FaturamentoPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isBelow1800 = useMediaQuery("(max-width:1799px)");
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [roleId, setRoleId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [dateRanges1, setDateRanges1] = useState([null, null]);
  const [dateRanges2, setDateRanges2] = useState([null, null]);
  const [comparisonData, setComparisonData] = useState(null);
  const [openYearModal, setOpenYearModal] = useState(false);
  const [yearSummaryData, setYearSummaryData] = useState(null);
  const [selectedMetricKey, setSelectedMetricKey] = useState(null);
  const [selectedMetricLabel, setSelectedMetricLabel] = useState(null);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);

  const handleCompareDates = async () => {
    if (!dateRanges1[0] || !dateRanges1[1] || !dateRanges2[0] || !dateRanges2[1]) {
      console.error("Selecione todas as datas antes de comparar.");
      return;
    }
    try {
      const payload = {
        start_date_1: formatDate(dateRanges1[0]),
        end_date_1: formatDate(dateRanges1[1]),
        start_date_2: formatDate(dateRanges2[0]),
        end_date_2: formatDate(dateRanges2[1]),
      };
      const response = await axios.post(`${API_URL}/bi/compare-dates`, payload, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      });
      setComparisonData(response.data);
    } catch (error) {
      console.error("Erro ao comparar datas:", error);
    }
  };

  const handleOpenYearModal = async (metricKey, metricLabel) => {
    try {
      const response = await axios.get(`${API_URL}/faturamento-year-summary?metric=${metricKey}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setYearSummaryData(response.data);
      setSelectedMetricKey(metricKey);
      setSelectedMetricLabel(metricLabel);
      setOpenYearModal(true);
    } catch (error) {
      console.error("Erro ao buscar resumo do faturamento do ano:", error);
    }
  };

  const handleCloseYearModal = () => setOpenYearModal(false);

  const label1Month = dateRanges1[0] ? formatMonth(dateRanges1[0]) : "Intervalo 1";
  const label2Month = dateRanges2[0] ? formatMonth(dateRanges2[0]) : "Intervalo 2";
  const sameMonth = label1Month === label2Month && label1Month !== "Intervalo 1";
  const label1Text = sameMonth ? `${label1Month} 1` : label1Month;
  const label2Text = sameMonth ? `${label2Month} 2` : label2Month;
  const gridProps = !isBelow1800 ? { xs: 12, sm: 6, md: 3 } : { xs: 12, sm: 6, md: 4 };
  let chartWidth = 250;
  let chartHeight = 200;
  if (!isMdDown && isBelow1800) {
    chartWidth = 300;
    chartHeight = 220;
  } else if (!isBelow1800) {
    chartWidth = 350;
    chartHeight = 250;
  }

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ width: "100%", backgroundColor: "#fff", color: theme.palette.text.primary }}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleDrawerToggle} edge="start" sx={{ mr: 2 }}>
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="h6">📊 Faturamento - Dashboard</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <MenuDrawer drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} roleId={roleId} />
      <Box component="main" sx={{ flexGrow: 1, minHeight: "100vh", mt: 8, p: 3, backgroundColor: "#eef2f6", borderRadius: 3 }}>
        <Box sx={{ backgroundColor: "#fff", borderRadius: 2, border: "1px solid #ccc", p: 3, mb: 3, boxShadow: 1 }}>
          <Typography variant="h5" gutterBottom>📊 Faturamento - Dashboard</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker label="Data Inicial 1" value={dateRanges1[0]} onChange={(val) => setDateRanges1([val, dateRanges1[1]])} />
                <DatePicker label="Data Final 1" value={dateRanges1[1]} onChange={(val) => setDateRanges1([dateRanges1[0], val])} />
              </Box>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker label="Data Inicial 2" value={dateRanges2[0]} onChange={(val) => setDateRanges2([val, dateRanges2[1]])} />
                <DatePicker label="Data Final 2" value={dateRanges2[1]} onChange={(val) => setDateRanges2([dateRanges2[0], val])} />
              </Box>
              <Button variant="contained" color="primary" onClick={handleCompareDates}>Comparar Intervalos de Datas</Button>
            </Box>
          </LocalizationProvider>
        </Box>

        {comparisonData ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Indicadores por Período</Typography>
            <Typography variant="body1">{label1Text}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{label2Text}</Typography>
            <Grid container spacing={2}>
              {metrics.map((metric) => {
                const value1 = comparisonData.interval_1[`average_${metric.key}`];
                const value2 = comparisonData.interval_2[`average_${metric.key}`];
                return (
                  <Grid item {...gridProps} key={metric.key}>
                    <Card
                      sx={{ backgroundColor: theme.palette.background.paper, mb: 2, p: 2, cursor: "pointer", '&:hover': { boxShadow: 6 } }}
                      onClick={() => handleOpenYearModal(metric.key, metric.label)}
                    >
                      <CardContent>
                        <Box sx={{ display: "inline-block", backgroundColor: "#fff", borderRadius: 2, boxShadow: 1, p: 1 }}>
                          <BarChart
                            borderRadius={7}
                            width={chartWidth}
                            height={chartHeight}
                            barLabel={(bar) => typeof bar.value === "number" ? bar.value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0,00"}
                            xAxis={[{ scaleType: "band", data: [label1Text, label2Text], tick: { style: { fill: theme.palette.text.primary } } }]}
                            yAxis={[{ tickFormatter: (value) => value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }]}
                            series={[{ data: [value1 || 0, value2 || 0], label: metric.label, color: "#1976d2" }]}
                          />
                        </Box>
                        <Typography variant="body2" sx={{ textAlign: "center", mt: 1 }}>
                          Diferença: {comparisonData[`percentage_${metric.key}`]?.toFixed(1) || 0}%
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          <Typography variant="body1">Nothing to display</Typography>
        )}
      </Box>

      <Dialog open={openYearModal} onClose={handleCloseYearModal} maxWidth="md" fullWidth>
        <DialogTitle>
          Resumo - {selectedMetricLabel}
        </DialogTitle>
        <DialogContent>
          {yearSummaryData ? (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" align="center" gutterBottom>
                {selectedMetricLabel} mês a mês
              </Typography>
              <BarChart
                width={600}
                height={300}
                borderRadius={7}
                barLabel={(bar) => typeof bar.value === "number" ? bar.value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0,00"}
                xAxis={[{ scaleType: "band", data: yearSummaryData.summary.map((item) => item.month), tick: { style: { fill: theme.palette.text.primary } } }]}
                yAxis={[{ tickFormatter: (value) => value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }]}
                series={[{ data: yearSummaryData.summary.map((item) => item[selectedMetricKey] || 0), label: selectedMetricLabel }]}
              />
            </Box>
          ) : (
            <Typography variant="body1" align="center">Carregando dados...</Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCloseYearModal}>Fechar</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
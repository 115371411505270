// ./components/pages/LojaPage.jsx
import React from "react";

const LojaPage = () => {
  return (
    <div>
      <h1>Loja Page</h1>
      <p>Conteúdo da página de Loja.</p>
    </div>
  );
};

export default LojaPage;

import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Card,
  CardContent,
  Button
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { BarChart } from "@mui/x-charts/BarChart";

const API_URL = "http://108.165.179.193:8000";

// Sidebar com as rotas de navegação
const sidebarItems = [
  { name: "Marketing", route: "/marketing", subcategories: [] },
  {
    name: "Operação",
    subcategories: [
      { name: "Delivery", route: "/delivery" },
      { name: "Loja", route: "/loja" }
    ]
  },
  {
    name: "Receita",
    subcategories: [
      { name: "Faturamento", route: "/faturamento" },
      { name: "Pratos", route: "/pratos" },
      { name: "DRE", route: "/dre" }
    ]
  }
];

const DrePage = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dateRanges1, setDateRanges1] = useState([null, null]);
  const [dateRanges2, setDateRanges2] = useState([null, null]);
  const [comparisonData, setComparisonData] = useState(null);

  // Formata a data para o padrão "yyyy-MM-dd"
  const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);

  const handleCompareDates = async () => {
    if (!dateRanges1[0] || !dateRanges1[1] || !dateRanges2[0] || !dateRanges2[1]) {
      console.error("⚠️ Selecione todas as datas antes de comparar.");
      return;
    }
    try {
      const token = auth?.token;
      if (!token) {
        console.error("Usuário não autenticado.");
        return;
      }
      const payload = {
        start_date_1: formatDate(dateRanges1[0]),
        end_date_1: formatDate(dateRanges1[1]),
        start_date_2: formatDate(dateRanges2[0]),
        end_date_2: formatDate(dateRanges2[1])
      };
      console.log("📡 Enviando para API (DRE):", payload);
      const response = await axios.post(`${API_URL}/dre/compare-dates-dre`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      console.log("📊 Dados de DRE:", response.data);
      setComparisonData(response.data);
    } catch (error) {
      console.error("❌ Erro ao comparar datas (DRE):", error);
    }
  };

  const label1Text =
    dateRanges1[0] && dateRanges1[1]
      ? `${formatDate(dateRanges1[0])} - ${formatDate(dateRanges1[1])}`
      : "Intervalo 1";
  const label2Text =
    dateRanges2[0] && dateRanges2[1]
      ? `${formatDate(dateRanges2[0])} - ${formatDate(dateRanges2[1])}`
      : "Intervalo 2";

  // Lista dos indicadores de DRE – keys devem corresponder aos campos retornados pelo backend
  const metrics = [
    { key: "cmv_valor", label: "CMV VALOR" },
    { key: "cmv_percent", label: "CMV %" },
    { key: "mkt", label: "MARKETING" },
    { key: "mao_de_obra", label: "MAO DE OBRA" },
    { key: "despesa_fixa", label: "DESPESA FIXA" },
    { key: "despesa_variavel", label: "DESPESA VARIAVEL" },
    { key: "estoque", label: "ESTOQUE" }
  ];

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", backgroundColor: "#121212", color: "white" }}>
      {/* Sidebar de Navegação */}
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: 240, backgroundColor: "#1E1E2D", color: "white" }
        }}
      >
        <List>
          {sidebarItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem button onClick={() => navigate(item.route)}>
                <ListItemText primary={item.name} />
              </ListItem>
              {item.subcategories && item.subcategories.length > 0 && (
                <List component="div" disablePadding>
                  {item.subcategories.map((sub, subIndex) => (
                    <ListItem button key={subIndex} sx={{ pl: 4 }} onClick={() => navigate(sub.route)}>
                      <ListItemText primary={sub} />
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      {/* Conteúdo Principal */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h5" gutterBottom>
          📊 DRE - Dashboard
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ mb: 3 }}>
            <Typography>Selecione os Intervalos de Datas:</Typography>
            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <DatePicker
                label="Data Inicial 1"
                value={dateRanges1[0]}
                onChange={(newValue) => {
                  console.log("Data Inicial 1 selecionada:", newValue);
                  setDateRanges1([newValue, dateRanges1[1]]);
                }}
              />
              <DatePicker
                label="Data Final 1"
                value={dateRanges1[1]}
                onChange={(newValue) => {
                  console.log("Data Final 1 selecionada:", newValue);
                  setDateRanges1([dateRanges1[0], newValue]);
                }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <DatePicker
                label="Data Inicial 2"
                value={dateRanges2[0]}
                onChange={(newValue) => {
                  console.log("Data Inicial 2 selecionada:", newValue);
                  setDateRanges2([newValue, dateRanges2[1]]);
                }}
              />
              <DatePicker
                label="Data Final 2"
                value={dateRanges2[1]}
                onChange={(newValue) => {
                  console.log("Data Final 2 selecionada:", newValue);
                  setDateRanges2([dateRanges2[0], newValue]);
                }}
              />
            </Box>
            <Button variant="contained" color="primary" onClick={handleCompareDates}>
              Comparar Intervalos de Datas
            </Button>
          </Box>
        </LocalizationProvider>
        {comparisonData ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ color: "white" }}>
              Médias dos Indicadores de DRE
            </Typography>
            <Typography variant="body1" sx={{ color: "white" }}>
              {label1Text}
            </Typography>
            <Typography variant="body1" sx={{ color: "white" }}>
              {label2Text}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {metrics.map((metric) => (
                <Grid item xs={12} sm={6} md={4} key={metric.key}>
                  <Card sx={{ backgroundColor: "#2A2A3B", mb: 2, p: 2 }}>
                    <CardContent>
                      <BarChart
                        width={350}
                        height={250}
                        barLabel={(bar) => bar.value.toFixed(1)}
                        xAxis={[
                          {
                            scaleType: "band",
                            data: [label1Text, label2Text],
                            tick: { style: { fill: "#ffffff" } }
                          }
                        ]}
                        yAxis={[{ tick: { style: { fill: "#ffffff" } } }]}
                        series={[
                          {
                            data: [
                              comparisonData.interval_1["average_" + metric.key],
                              comparisonData.interval_2["average_" + metric.key]
                            ],
                            label: metric.label,
                            color: "#1976d2"
                          }
                        ]}
                      />
                      <Typography variant="body2" sx={{ color: "#ffffff", textAlign: "center", mt: 1 }}>
                        Diferença:{" "}
                        {comparisonData["percentage_" + metric.key]
                          ? comparisonData["percentage_" + metric.key].toFixed(1)
                          : "0"}
                        %
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: "#ffffff" }}>
            Nothing to display
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DrePage;

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// Ícones do MUI
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import BuildIcon from "@mui/icons-material/Build";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

// Date Pickers
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Contexto de Autenticação
import { AuthContext } from "../../contexts/AuthContext";

// Imagens
import logo from "../../assets/images/logo-200px.png";
import RoboIcon from "../../assets/images/robo.svg";
import GraficoIcon from "../../assets/images/grafico.svg";

// Biblioteca jsPDF
import { jsPDF } from "jspdf";

// URL base do backend (caso seja necessário para outras operações)
const API_URL = "http://108.165.179.193:8000";

// Largura do Drawer
const drawerWidth = 230;

// ============== STYLED COMPONENTS ==============
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: `-${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: 0,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    backgroundColor: "#fff",
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

// ============== FUNÇÃO PARA GERAR LOTE ==============
function gerarLote(dataRef) {
  const d = new Date(dataRef);
  const yyyy = d.getFullYear();
  const mm = String(d.getMonth() + 1).padStart(2, "0");
  const dd = String(d.getDate()).padStart(2, "0");
  const random = Math.floor(Math.random() * 1000);
  return `${yyyy}${mm}${dd}-${random}`;
}

// ============== FUNÇÃO PARA FORMATAR DATA ==============
function formatDate(dateObj) {
  if (!dateObj) return "";
  const d = new Date(dateObj);
  const yyyy = d.getFullYear();
  const mm = String(d.getMonth() + 1).padStart(2, "0");
  const dd = String(d.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
}

// ============== COMPONENTE PRINCIPAL ==============
export default function ProducaoPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  // Verifica token e role
  const [roleId, setRoleId] = useState(null);
  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  // Controle do Drawer
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  // (Opcional) Impressoras disponíveis, se necessário
  const [availablePrinters, setAvailablePrinters] = useState([]);
  const [selectedPrinterIndex, setSelectedPrinterIndex] = useState(0);

  // Lista de produtos
  const produtos = [
    "Strogonoff de Carne",
    "Strogonoff de Frango",
    "Carne para Strogonoff",
    "Bolonhesa",
    "Molho Branco",
    "Molho Vermelho",
    "Carne de Panela",
  ];
  const [selectedProduto, setSelectedProduto] = useState(produtos[0]);

  // Estados do formulário
  const [medida, setMedida] = useState("kg");
  const [valorMedida, setValorMedida] = useState("");
  const [dataProd, setDataProd] = useState(new Date());
  const [lote, setLote] = useState("");
  // Quantidade de etiquetas a imprimir
  const [quantidade, setQuantidade] = useState(1);

  // (Opcional) Busca impressoras do backend
  useEffect(() => {
    const fetchPrinters = async () => {
      try {
        const response = await axios.get(`${API_URL}/printers`);
        if (response.data && Array.isArray(response.data)) {
          setAvailablePrinters(response.data);
        } else {
          setAvailablePrinters([]);
        }
      } catch (error) {
        console.error("Erro ao buscar impressoras:", error);
        setAvailablePrinters([]);
      }
    };
    fetchPrinters();
  }, []);

  // Gera lote automaticamente na montagem
  useEffect(() => {
    setLote(gerarLote(new Date()));
  }, []);

  // Handlers do formulário
  const handleProdutoChange = (e) => setSelectedProduto(e.target.value);
  const handleMedidaChange = (e) => setMedida(e.target.value);
  const handleValorChange = (e) => setValorMedida(e.target.value);
  const handleDataChange = (newValue) => {
    setDataProd(newValue);
    setLote(gerarLote(newValue));
  };
  const handleQuantidadeChange = (e) => {
    const q = parseInt(e.target.value, 10);
    setQuantidade(isNaN(q) || q < 1 ? 1 : q);
  };

  // Função para gerar e imprimir o PDF
  const handleImprimir = () => {
    // Cria o PDF com jsPDF em milímetros com tamanho personalizado: 210 x 200 mm (20 cm de altura)
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [210, 200],
    });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);

    let cursorY = 10; // margem superior inicial
    // Loop para gerar a quantidade desejada de etiquetas
    // Agora, em vez de linhas separadas para "Medida" e "Valor",
    // vamos imprimir uma linha única no formato: (valor) (unidade)
    for (let i = 0; i < quantidade; i++) {
      doc.text(`Produto: ${selectedProduto}`, 10, cursorY);
      cursorY += 10;
      // Combina valor e unidade em uma única linha
      const unidade = medida === "kg" ? "Kilogramas" : "Gramas";
      doc.text(`${valorMedida} ${unidade}`, 10, cursorY);
      cursorY += 10;
      doc.text(`Data: ${formatDate(dataProd)}`, 10, cursorY);
      cursorY += 10;
      doc.text(`LOTE: ${lote}`, 10, cursorY);
      cursorY += 15; // espaço entre etiquetas

      // Se o cursor estiver próximo do final da página, adiciona nova página
      if (cursorY > 180) {
        doc.addPage();
        cursorY = 10;
      }
    }

    // Gera o blob do PDF
    const pdfBlob = doc.output("blob");
    // Cria um URL para o blob
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Cria um iframe oculto e define o src para o blobUrl
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = blobUrl;
    document.body.appendChild(iframe);

    // Quando o iframe carregar, aguarda 500ms, aciona a impressão e aguarda 3 segundos antes de remover
    iframe.onload = function () {
      setTimeout(() => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 3000);
      }, 500);
    };
  };

  // Navegação no Drawer
  const handleNavigation = (path) => {
    navigate(path);
  };

  // Conteúdo do Drawer
  const drawerContent = (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box component="img" src={logo} alt="Logo" sx={{ width: "70%", ml: 0 }} />
        <IconButton onClick={handleDrawerToggle}>
          <ChevronLeftIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ px: 1 }}>
        <ListItem button onClick={() => handleNavigation("/LojaIA")}>
          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
            <Box component="img" src={RoboIcon} alt="IA de Caixa" sx={{ width: 20, height: 20 }} />
          </ListItemIcon>
          <ListItemText primary="IA de Caixa" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation("/bipage")}>
          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
            <Box component="img" src={GraficoIcon} alt="B.I" sx={{ width: 20, height: 20 }} />
          </ListItemIcon>
          <ListItemText primary="B.I" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation("/producao")}>
          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
            <WorkOutlineIcon sx={{ fontSize: 20 }} />
          </ListItemIcon>
          <ListItemText primary="Produção" />
        </ListItem>
        <ListItem button onClick={() => alert("Manutenção em andamento...")}>
          <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
            <BuildIcon sx={{ fontSize: 20 }} />
          </ListItemIcon>
          <ListItemText primary="Maintenance" />
        </ListItem>
        {roleId === 1 && (
          <ListItem button onClick={() => handleNavigation("/register")}>
            <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
              <PersonAddIcon sx={{ fontSize: 20 }} />
            </ListItemIcon>
            <ListItemText primary="Registrar Usuário" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              mr: 2,
              display: !isMobile && drawerOpen ? "none" : "block",
            }}
          >
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Produção - Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBarStyled>
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            height: isMobile ? "100vh" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            marginTop: 0,
            boxSizing: "border-box",
            backgroundColor: "#fff",
            color: theme.palette.text.primary,
            borderRight: "none",
            boxShadow: "none",
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Main open={drawerOpen}>
        <Toolbar />
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            border: "1px solid #ccc",
            p: 3,
            mb: 3,
            boxShadow: 1,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Etiqueta de Produção
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 3,
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {/* Produto */}
              <Typography variant="subtitle1">Produto</Typography>
              <FormControl fullWidth>
                <InputLabel id="produto-label">Selecione</InputLabel>
                <Select
                  labelId="produto-label"
                  value={selectedProduto}
                  label="Selecione"
                  onChange={handleProdutoChange}
                >
                  {produtos.map((prod, idx) => (
                    <MenuItem key={idx} value={prod}>
                      {prod}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Medida e Valor */}
              <Typography variant="subtitle1">Medida e Valor</Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="medida-label">Unidade</InputLabel>
                  <Select
                    labelId="medida-label"
                    value={medida}
                    label="Unidade"
                    onChange={handleMedidaChange}
                  >
                    <MenuItem value="kg">Kilogramas</MenuItem>
                    <MenuItem value="g">Gramas</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Valor"
                  type="number"
                  value={valorMedida}
                  onChange={handleValorChange}
                  fullWidth
                />
              </Box>
              {/* Data */}
              <Typography variant="subtitle1">Data</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data de Hoje"
                  value={dataProd}
                  onChange={handleDataChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
              {/* Lote */}
              <Typography variant="subtitle1">LOTE</Typography>
              <TextField
                label="Lote"
                value={lote}
                InputProps={{ readOnly: true }}
                fullWidth
              />
              {/* Quantidade de Etiquetas */}
              <Typography variant="subtitle1">Quantidade de Etiquetas</Typography>
              <TextField
                label="Quantidade"
                type="number"
                value={quantidade}
                onChange={handleQuantidadeChange}
                fullWidth
              />
              {/* Botão Gerar PDF/Imprimir */}
              <Button variant="contained" color="primary" onClick={handleImprimir}>
                Imprimir (Gerar PDF)
              </Button>
            </Box>
            {/* Espaço para pré-visualização (opcional) */}
            <Box sx={{ flex: 1, minWidth: 280 }}>
              <Typography variant="subtitle2">
                Aqui você pode mostrar uma prévia do que vai no PDF...
              </Typography>
            </Box>
          </Box>
        </Box>
      </Main>
    </Box>
  );
}

// src/components/pages/LojaIAPage.jsx

import React, {
    useState,
    useRef,
    useEffect,
    useContext
  } from "react";
  import {
    AppBar,
    Box,
    Button,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Toolbar,
    Typography,
    Collapse,
    useMediaQuery
  } from "@mui/material";
  import { useTheme } from "@mui/material/styles";
  import MenuIcon from "@mui/icons-material/Menu";
  import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
  import ExpandLess from "@mui/icons-material/ExpandLess";
  import ExpandMore from "@mui/icons-material/ExpandMore";
  import BuildIcon from "@mui/icons-material/Build";
  import { FiSend } from "react-icons/fi";
  import { useNavigate } from "react-router-dom";
  
  import { AuthContext } from "../../contexts/AuthContext";
  
  // Ícones e imagens
  import logo from "../../assets/images/logo-200px.png";
  import RoboIcon from "../../assets/images/robo.svg";
  import GraficoIcon from "../../assets/images/grafico.svg";
  import ProducaoIcon from "../../assets/images/producao.svg";
  import UserIcon from "../../assets/images/user.svg";
  
  // Configurações
  const API_URL = "http://108.165.179.193:8000/chat/";
  const drawerWidth = 230;
  
  export default function LojaIAPage() {
    const { auth, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
    // Controle do Drawer
    const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  
    // Submenus
    const [biOpen, setBiOpen] = useState(true);
    const [operacaoOpen, setOperacaoOpen] = useState(false);
    const [receitaOpen, setReceitaOpen] = useState(false);
  
    // Chat
    const [userInput, setUserInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const chatContainerRef = useRef(null);
  
    // Autenticação
    const [roleId, setRoleId] = useState(null);
  
    useEffect(() => {
      if (!auth?.token) {
        navigate("/");
        return;
      }
      try {
        const payload = JSON.parse(atob(auth.token.split(".")[1]));
        setRoleId(payload.role_id);
      } catch (error) {
        console.error("Erro ao decodificar token:", error);
        logout();
      }
    }, [auth.token, navigate, logout]);
  
    // Rola automaticamente para a última mensagem
    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, [messages]);
  
    const handleDrawerToggle = () => {
      setDrawerOpen(!drawerOpen);
    };
  
    const handleBiToggle = () => setBiOpen(!biOpen);
    const handleOperacaoToggle = () => setOperacaoOpen(!operacaoOpen);
    const handleReceitaToggle = () => setReceitaOpen(!receitaOpen);
  
    const handleNavigation = (path) => {
      navigate(path);
    };
  
    // Envia mensagem para a API
    const handleSendMessage = async () => {
      if (!userInput.trim()) return;
  
      const newMessage = { role: "user", content: userInput };
      setMessages((prev) => [...prev, newMessage]);
      setUserInput("");
      setIsLoading(true);
  
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setMessages((prev) => [
            ...prev,
            {
              role: "system",
              content: "❌ Token de autenticação não encontrado. Faça login novamente."
            }
          ]);
          setIsLoading(false);
          return;
        }
  
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            message: newMessage.content,
            bot_name: "LOJA IA"
          })
        });
  
        if (!response.ok) {
          throw new Error(`Erro ${response.status}: ${response.statusText}`);
        }
  
        const data = await response.json();
        const botMessage = {
          role: "bot",
          content: data.response || "⚠️ Sem resposta da API."
        };
        setMessages((prev) => [...prev, botMessage]);
      } catch (error) {
        setMessages((prev) => [
          ...prev,
          { role: "system", content: `❌ Erro ao conectar com a API: ${error.message}` }
        ]);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Conteúdo do Drawer
    const drawerContent = (
      <Box sx={{ p: 2 }}>
        {/* Logo + Botão de Fechar Drawer */}
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box component="img" src={logo} alt="Logo" sx={{ width: "70%", ml: 0 }} />
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Box>
  
        <List sx={{ px: 1 }}>
          {/* IA de Caixa (LojaIA) */}
          <ListItem button selected onClick={() => handleNavigation("/LojaIA")}>
            <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
              <Box component="img" src={RoboIcon} alt="IA de Caixa" sx={{ width: 20, height: 20 }} />
            </ListItemIcon>
            <ListItemText
              primary="IA de Caixa"
              primaryTypographyProps={{
                fontSize: 14,
                fontFamily: "Roboto, sans-serif",
                color: "#364152"
              }}
            />
          </ListItem>
  
          {/* Produção */}
          <ListItem button onClick={() => handleNavigation("/producao")}>
            <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
              <Box
                component="img"
                src={ProducaoIcon}
                alt="Produção"
                sx={{ width: 20, height: 20 }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Produção"
              primaryTypographyProps={{
                fontSize: 14,
                fontFamily: "Roboto, sans-serif",
                color: "#364152"
              }}
            />
          </ListItem>
  
          {/* B.I */}
        {roleId === 1 && (
          <ListItem button onClick={() => handleNavigation("/bipage")}>
            <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
              <Box
                component="img"
                src={GraficoIcon}
                alt="B.I"
                sx={{ width: 20, height: 20 }}
              />
            </ListItemIcon>
            <ListItemText primary="B.I" />
          </ListItem>
        )}
          <Collapse in={biOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Marketing */}
              <ListItem button sx={{ pl: 4 }} onClick={() => handleNavigation("/marketing")}>
                <ListItemText
                  primary="Marketing"
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontFamily: "Roboto, sans-serif",
                    color: "#364152"
                  }}
                />
              </ListItem>
  
              {/* Operação */}
              <ListItem button sx={{ pl: 4 }} onClick={handleOperacaoToggle}>
                <ListItemText
                  primary="Operação"
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontFamily: "Roboto, sans-serif",
                    color: "#364152"
                  }}
                />
                {operacaoOpen ? (
                  <ExpandLess sx={{ fontSize: 20 }} />
                ) : (
                  <ExpandMore sx={{ fontSize: 20 }} />
                )}
              </ListItem>
              <Collapse in={operacaoOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button sx={{ pl: 6 }} onClick={() => handleNavigation("/delivery")}>
                    <ListItemText
                      primary="Delivery"
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontFamily: "Roboto, sans-serif",
                        color: "#364152"
                      }}
                    />
                  </ListItem>
                </List>
              </Collapse>
  
              {/* Receita */}
              <ListItem button sx={{ pl: 4 }} onClick={handleReceitaToggle}>
                <ListItemText
                  primary="Receita"
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontFamily: "Roboto, sans-serif",
                    color: "#364152"
                  }}
                />
                {receitaOpen ? (
                  <ExpandLess sx={{ fontSize: 20 }} />
                ) : (
                  <ExpandMore sx={{ fontSize: 20 }} />
                )}
              </ListItem>
              <Collapse in={receitaOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button sx={{ pl: 6 }} onClick={() => handleNavigation("/faturamento")}>
                    <ListItemText
                      primary="Faturamento"
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontFamily: "Roboto, sans-serif",
                        color: "#364152"
                      }}
                    />
                  </ListItem>
                  <ListItem button sx={{ pl: 6 }} onClick={() => handleNavigation("/pratos")}>
                    <ListItemText
                      primary="Pratos"
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontFamily: "Roboto, sans-serif",
                        color: "#364152"
                      }}
                    />
                  </ListItem>
                  <ListItem button sx={{ pl: 6 }} onClick={() => handleNavigation("/dre")}>
                    <ListItemText
                      primary="DRE"
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontFamily: "Roboto, sans-serif",
                        color: "#364152"
                      }}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Collapse>
  
  
          {/* Registrar Usuário (apenas se roleId === 1) */}
          {roleId === 1 && (
            <ListItem button onClick={() => handleNavigation("/register")}>
              <ListItemIcon sx={{ color: theme.palette.text.secondary }}>
                <Box component="img" src={UserIcon} alt="Registrar Usuário" sx={{ width: 20, height: 20 }} />
              </ListItemIcon>
              <ListItemText
                primary="Registrar Usuário"
                primaryTypographyProps={{
                  fontSize: 14,
                  fontFamily: "Roboto, sans-serif",
                  color: "#364152"
                }}
              />
            </ListItem>
          )}
        </List>
      </Box>
    );
  
    return (
      <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
        <CssBaseline />
  
        {/* AppBar Superior */}
        <AppBar
          position="fixed"
          sx={{
            width: !isMobile && drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
            ml: !isMobile && drawerOpen ? `${drawerWidth}px` : 0,
            backgroundColor: "#fff",
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[2],
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                mr: 2,
                display: !isMobile && drawerOpen ? "none" : "block",
              }}
            >
              <MenuIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              IA de Caixa - LojaIA
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="contained" color="secondary" onClick={logout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
  
        {/* Drawer Lateral */}
        <Drawer
          variant={isMobile ? "temporary" : "persistent"}
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              height: isMobile ? "100vh" : "calc(100vh - 64px)",
              paddingLeft: "16px",
              marginTop: 0,
              boxSizing: "border-box",
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              borderRight: "none",
              boxShadow: "none",
            },
          }}
        >
          {drawerContent}
        </Drawer>
  
        {/* Área Principal */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: "100vh",
            m: isMobile ? 1 : 3,
            borderRadius: 3,
            backgroundColor: "#eef2f6",
            mt: { xs: 8, sm: 8 },
            p: 3,
          }}
        >
          {/* Título da Página */}
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              border: "1px solid #ccc",
              p: 3,
              mb: 3,
              boxShadow: 1,
            }}
          >
            <Typography variant="h5" gutterBottom>
              🤖 LojaIA - Chat
            </Typography>
            <Typography variant="body2">
              Converse com a IA de Caixa (LojaIA) para obter informações e suporte.
            </Typography>
          </Box>
  
          {/* Área do Chat */}
          <Paper
            elevation={3}
            sx={{
              p: 3,
              bgcolor: "background.paper",
              height: "60vh",
              overflowY: "auto",
              mb: 2,
            }}
            ref={chatContainerRef}
          >
            {messages.map((msg, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{ mb: 1, whiteSpace: "pre-wrap" }} // Importante: pre-wrap para respeitar quebras de linha
              >
                <strong>{msg.role === "user" ? "Você:" : msg.role === "bot" ? "Bot:" : "Sistema:"}</strong>{" "}
                {msg.content}
              </Typography>
            ))}
            {isLoading && (
              <Typography variant="body1">⏳ Carregando resposta...</Typography>
            )}
          </Paper>
  
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="Digite sua mensagem"
              variant="outlined"
              fullWidth
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              disabled={isLoading || !userInput.trim()}
            >
              {isLoading ? "⏳" : <FiSend />}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  